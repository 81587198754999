module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_@swc+core@1.10.1_@swc+helpers@0.5.15__babel-eslin_rszg22n2p6fkmbxuv6eerbqdwe/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b713d9d29177bec6d655ab049400c829"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.1_@swc+core@1.10.1_@swc+helpers@0.5.15__babel-es_y56hliwvzyjex6cjc4fu2ldeka/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HLCZKG3JC4"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.14.0_gatsby@5.14.1_@swc+core@1.10.1_@swc+helpers@0.5.15__babel-eslint@_uyg3ehoyfygllapubaldm2liwi/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_@swc+core@1.10.1_@swc+helpers@0.5.15__babel-eslint@10.1.0_eslint@8.57.1__eslint_wmyh6ylnbpw27rzsrpf7bfmgc4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
