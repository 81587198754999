import { Link as OriginLink, navigate } from 'gatsby';
import type { ComponentProps } from 'react';
import { useCallback, forwardRef } from 'react';
import { useLocation } from '@reach/router';

type LinkProps = Omit<ComponentProps<typeof OriginLink>, 'to' | 'ref'>;

export const Routes = {"indexPage":"/","404Page":"/404","aboutPage":"/about","blogPage":"/blog","linksPage":"/links"} as const;
export type Route = keyof typeof Routes;

export const Link = forwardRef<HTMLAnchorElement, LinkProps & { to: Route }>(({ to, ...rest }, ref): JSX.Element => {
  const location = useLocation();
  return <OriginLink
    ref={ref as any}
    to={Routes[to]}
    aria-current={location.pathname === Routes[to] ? 'page' : undefined}
    {...rest}
  />;
});

// export function useNavigate(): { navigateTo: (route: Route) => void } {
//   return { navigateTo: useCallback((route: Route) => navigate(Routes[route]), []) };
// }